<template>
    <div>
        <form-create
            name="form"
            :option="dataOptions"
            :rule="dataRule"
            v-model="fApi"
            :value.sync="value"
        />
    </div>
</template>

<script>
const dao = require("../api/core/dao");
const utils = require("../api/core/utils");
import { getComponent } from "@/api/core/component";

export default {
    props: {
        rule: {
            type: Array,
        },
        options: {
            type: Object,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        $route: {
            handler: function (val) {
                this.url = val.path;
                this.init();
                this.$forceUpdate();
            },
        },
    },
    data() {
        return {
            url: "",
            fApi: {},
            // 用于接收数据源
            value: {},
            dataRule: [],
            dataOptions: {},
        };
    },
    async created() {
        this.fApi.data = this.value;
        await this.init();
    },
    methods: {
        getApi() {
            return this.fApi;
        },
        async getConfig() {
            let routeKey = "config/setRouter";
            let route = this.$store.getters.router;
            if (utils.isNull(route)) {
                route = localStorage.getItem(routeKey);
                if (!utils.isNull(route)) {
                    route = JSON.parse(JSON.stringify(route));
                    this.$store.dispatch(routeKey, JSON.parse(route));
                } else {
                    let res = await dao.getConfig("config", "route");
                    if (res.success) {
                        this.$store.dispatch(routeKey, res.data);
                        localStorage.setItem(routeKey, JSON.stringify(res.data));
                    }
                }
            }
            let pageKey = "config/setPage";
            let page = this.$store.getters.page;
            if (utils.isNull(page)) {
                page = localStorage.getItem(pageKey);
                if (!utils.isNull(page)) {
                    page = JSON.parse(JSON.stringify(page));
                    this.$store.dispatch(pageKey, JSON.parse(page));
                } else {
                    let res2 = await dao.getConfig("config", "page");
                    if (res2.success) {
                        this.$store.dispatch(pageKey, res2.data);
                        localStorage.setItem(pageKey, JSON.stringify(res2.data));
                    }
                }
            }
        },
        // 设置网站图标
        async handleFavicon() {
            let favicon_url = localStorage.getItem("favicon_url");
            if (!favicon_url) {
                const favicon_date = localStorage.getItem("favicon_date");
                const strDate = utils.dateToString(new Date());
                if (favicon_date && favicon_date !== null && favicon_date === strDate) {
                    return;
                }
                localStorage.setItem("favicon_date", strDate);

                const origin = window.location.origin;
                let res = await dao.apiGet("uws", "getFavicon", `website=${origin}`);
                if (!res.success || !res.data) {
                    return;
                }
                favicon_url = res.data;
                localStorage.setItem("favicon_url", favicon_url);
            }
            var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
            link.rel = "shortcut icon";
            link.href = favicon_url;
            document.getElementsByTagName("head")[0].appendChild(link);
        },
        async init() {
            var params = this.$route.params;
            var resRule = [];
            var resOption = {};
            if (utils.isNull(this.$store.getters.router)) {
                await this.getConfig();
            }
            await this.handleFavicon();
            if (params.app != undefined && params.app != null && params.code != undefined && params.code != null) {
                var search = {
                    app: params.app,
                    code: params.code,
                };
                const res = await getComponent(search);
                if (res.success && res.data.length != 0) {
                    let resData = res.data[0];
                    resRule = JSON.parse(resData.rule);
                    resOption = JSON.parse(resData.options);
                    let pageTitle = resOption.form.pageTitle;
                    if (pageTitle) {
                        document.title = pageTitle;
                    }
                } else {
                    this.dataRule = [];
                    this.dataOptions = {};
                    alert("未找到组件信息");
                    return;
                }
            } else {
                this.dataRule = [];
                this.dataOptions = {};
                alert("未找到组件信息");
                return;
            }

            // 设置打印配置
            if (resOption.form.printCss) {
                var style = document.createElement("style");
                style.innerHTML = "@media print { @page {" + resOption.form.printCss + "}}";
                window.document.head.appendChild(style);
            }

            //执行加载前脚本
            const _dataRule = resRule;
            const _dataOptions = resOption;
            this.$store.dispatch("api/setAPi", this.fApi);

            // let permissions = this.$store.getters.permissions;
            const permissionsKey = "permissions";
            let permissions = localStorage.getItem(permissionsKey);
            let isUpdate = false;
            if (!permissions || permissions === null || permissions.length === 0) {
                permissions = [];
                isUpdate = true;
            } else {
                permissions = JSON.parse(permissions);
            }
            const headerKey = "HEADER";
            let header = localStorage.getItem(headerKey);
            // 读取按钮权限
            if (header && header !== null && params.code !== "login") {
                if (isUpdate) {
                    if (utils.getUser().is_admin == 1) {
                        try {
                            let menuRes = await dao.view("uws", "menu1", { type: "function" });
                            menuRes.data.map((v) => {
                                permissions.push(v.code);
                            });
                        } catch (error) {}
                    } else {
                        try {
                            let menuRes = await dao.view("uws", "menu", { type: "function" });
                            menuRes.data.map((v) => {
                                permissions.push(v.code);
                            });
                        } catch (error) {}
                    }
                    if (permissions.length > 0) {
                        localStorage.setItem(permissionsKey, JSON.stringify(permissions));
                    }
                }
            } else {
                localStorage.setItem(permissionsKey, "");
            }

            if (permissions.length > 0) {
                this.$store.dispatch("permissions/setPermissions", permissions);
            }

            // 获取async函数的构造器
            // 通过上面的构造器创建async方法
            const beforeCreateScript = window.AsyncFunction("vm", "api", "value", "dao", "utils", _dataOptions.form.beforeCreateScript);
            let q = utils.getQueryParams();
            if (q.token) {
                utils.setHeader({ token: q.token });
            }
            if (_dataOptions.form.zoom) {
                document.body.style.zoom = _dataOptions.form.zoom;
            }

            await beforeCreateScript(this, this.fApi, this.value, dao, utils);
            this.fApi.data = this.value;
            this.dataRule = _dataRule;
            this.dataOptions = _dataOptions;
            this.$nextTick(() => {
                this.fApi
                    .all()
                    .filter((item) => item.type === "yes-dataSource")
                    .forEach((item) => {
                        item.__fc__.el && item.__fc__.el.initValue();
                        item.__fc__.el && item.__fc__.el.init();
                    });
                this.value = this.fApi.data;
            });

            this.$nextTick(async () => {
                const afterCreateScript = window.AsyncFunction("vm", "api", "value", "dao", "utils", _dataOptions.form.afterCreateScript);
                await afterCreateScript(this, this.fApi, this.value, dao, utils);

                let formList = document.getElementsByClassName("form-create");
                for (let i = 0; i < formList.length; i++) {
                    formList[i].style = _dataOptions.form.definedCss;
                }

                if (_dataOptions.form.iframeReturnScript) {
                    const iframeReturnScript = window.AsyncFunction("data", "vm", "api", "dao", "utils", _dataOptions.form.iframeReturnScript);
                    window.addEventListener("message", (event) => {
                        iframeReturnScript(event.data, this, this.fApi, dao, utils);
                    });
                }
            });
        },
    },
};
</script>
